// Add "More games" tile to the end of the list

export const useAddMoreElement = (list, category, presentationType, slug) => {
  let moreGamesTile = {};

  if (presentationType === 'CasinoGame') {
    moreGamesTile = {
      _id: 'more',
      active: true,
      game: {
        name: 'More Games',
        thumbnail: 'https://content.prod.platform.metawin.com/games/more-games-330x443.png',
        slug,
      },
      name: 'More Games',
      id: 'more',
      category,
      presentationType: 'CasinoGame',
      isHidden: false,
    };
  }
  list.push(moreGamesTile);
  return list;
};
