import { defineStore } from 'pinia';

export const useCasinoStore = defineStore({
  id: 'casino-store',
  state: () => {
    const config = useRuntimeConfig();
    return {
      instantWinGamesPromoted: [],
      liveGamesPromoted: [],
      dgaClient: null,
      dgaServer: config.public.DGA_SERVER,
      dgaCasinoId: config.public.DGA_CASINO_ID,
      dgaAvailableGames: null,
      gamesCollection: [],
      currency: 'USD',
    };
  },
  actions: {
    async loadInstantWinGamesPromoted() {
      // Get promoted games from Sanity CMS
      const nuxtApp = useNuxtApp();

      const res = await nuxtApp.$api('/game/collection/popular', {
        method: 'GET',
      });

      let games = res.items.map(item => ({
        ...item,
        presentationType: 'CasinoGame',
        category: 'casino-games-promoted',
      }));

      games = useAddMoreElement(games, 'casino-games-promoted', 'CasinoGame', '/');

      this.instantWinGamesPromoted = games;
    },
    async loadLiveGamesPromoted() {
      // Get promoted games from Sanity CMS
      const nuxtApp = useNuxtApp();
      const query = `*[_type == "live-games-promoted" && active] | order(orderRank) {
        _id,
        name,
        active,
        game,
        tags,
        order,
        orderRank,
        provider,
        providerRef
      }`;

      try {
        const data = await nuxtApp.$sanity.fetch(query);
        if (data?.length > 0) {
          let games = data.map((item) => {
            return {
              ...item,
              id: item?._id,
              category: 'live-games-promoted',
              presentationType: 'CasinoGame',
            };
          });
          games = useAddMoreElement(games, 'live-games-promoted', 'CasinoGame', '/');
          this.liveGamesPromoted = games;
        }
      } catch (err) {
        nuxtApp.$rollbar.error('loadLiveGamesPromoted Failed:', err);
      }
    },
    async getLiveGamesThemes() {
      const nuxtApp = useNuxtApp();
      const query = '*[_type == "filter-categories-live"] ';
      try {
        const data = await nuxtApp.$sanity.fetch(query);
        return data;
      } catch (err) {
        nuxtApp.$rollbar.error('getLiveGamesThemes Failed:', err);
      }
    },
    loadDgaScript() {
      if (document.getElementById('dga-script')) {
        this.dgaClient = window.dga;
        return true;
      }
      const config = useRuntimeConfig();
      function loadDgaScript() {
        useHead({
          script: [
            {
              src: config.public.DGA_SCRIPT_SRC,
              id: 'dga-script',
              type: 'text/javascript',
              body: true,
            },
          ],
        });
      }
      // Return promise to allow await for script to load
      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (!window.dga) {
            loadDgaScript();
          } else {
            this.dgaClient = window.dga;
            clearInterval(interval);
            resolve(true);
          }
        }, 200);
      });
    },
    connectDga() {
      this.dgaClient.connect(this.dgaServer, this.dgaCasinoId);
    },
    disconnectDga() {
      if (!this.dgaClient) { return; }
      this.dgaClient.disconnect();
    },
    resetCasinoStore() {
      this.disconnectDga();
      this.$reset();
    },
    async startDGA(gamesCollection) {
      if (!gamesCollection) {
        return;
      }
      this.gamesCollection = gamesCollection;
      if (!this.dgaClient) {
        await this.loadDgaScript();
      }
      this.dgaClient.onMessage = (data) => {
        if (data.tableKey) {
          this.dgaAvailableGames = data.tableKey;
          this.subscribeDGA();
          return;
        }

        if (data.tableId) {
          const game = this.gamesCollection.find(g => g.providerRef.toLowerCase() === data.tableId.toLowerCase());
          if (game) {
            game.dynamicData = data;
          }
        }
      };

      this.dgaClient.onConnect = () => {
        if (!this.dgaAvailableGames) { this.dgaClient?.available(this.dgaCasinoId); }
      };

      this.connectDga();
    },
    subscribeDGA() {
      if (!this.gamesCollection
        || this.gamesCollection.length === 0
        || !this.dgaAvailableGames
        || this.dgaAvailableGames.length === 0) {
        return;
      }

      for (const game of this.gamesCollection) {
        if (game.id === 'more') { continue; }

        if (!game.providerRef) {
          game.isHidden = true;
          continue;
        }

        // If game is an evolution game dont start the subscrition but still show the game
        if (game.provider !== 'PragmaticPlay') {
          game.isHidden = false;
          continue;
        }

        const dgaGameId = this.dgaAvailableGames.find(d => d.toLowerCase() === game.providerRef?.toLowerCase());
        if (dgaGameId) {
          this.dgaClient.subscribe(this.dgaCasinoId, dgaGameId, this.currency);
        } else {
          game.isHidden = true;
        }
      }
    },
  },
});
